<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import * as XLSX from "xlsx";

/**
 * Starter page component
 */
export default {
  page() {
    return {
      title: this.title,
      meta: [{ name: "description" }],
    };
  },
  components: { Layout, PageHeader },
  data() {
    return {
      title: "Starter page",
      items: [
        {
          text: "Utility",
          href: "/",
        },
        {
          text: "Starter page",
          active: true,
        },
      ],
      adminlist: [],
      adminsearch: "",
      ismobile: false,
      activeNames: [],
      activeapp: [],
      showmodel: "1",
      userinfo: [],
      safeinfo: [],
      loading: true,
      block: false,
      systemtype: 0,
      pagesize: 10,
      currentPage: 1,
      totalrows: 0,
      allpage: 0,
      filterDataShow: [], //过滤后的数据
      cashierconfig: [],
      cashierstatus: 0,
      withdrawalstatus: false,
      chargetype: false,
      chargedata: {
        uid: "",
        amount: "",
      },
      showamountchange: false,
    };
  },
  mounted() {
    var that = this;
    that.title = that.$t("menuitems.user.text");
    that.items = [
      {
        text: that.$t("menuitems.user.text"),
        active: true,
      },
    ];

    that.userinfo = JSON.parse(localStorage.getItem("user"));
    if (that.userinfo.topuid >= 1) {
      that.block = true;
    }
    let flag = navigator.userAgent.match(
      /(phone|pod|iPhone|iPod|ios|Android|Mobile|BlackBerry|IEMobile|MQQBrower|JUC|Fennec|wOSBrower|BrowerNG|WebOS|Symbian|Windows Phone)/i
    );
    if (flag) {
      // that.ismobile = true;
    }
    that.getadminlist();

    that.getsysteminfo();
    that.searchResource();
    that.getcashierconfig();
    that.getwithdrawalstatus();
    that.getchargetype();
  },
  methods: {
    getsysteminfo() {
      var that = this;
      that.$axios
        .post(
          that.apiuri,
          {
            action: "systeminfo",
          },
          {
            headers: { Openid: that.userinfo.openid },
          }
        )
        .then(function (response) {
          that.systemtype = parseInt(response.data.data.model);
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    getadminlist() {
      var that = this;
      that.$axios
        .post(
          that.apiuri,
          {
            action: "userlist",
          },
          {
            headers: { Openid: that.userinfo.openid },
          }
        )
        .then(function (response) {
          that.loading = false;
          that.adminlist = response.data.data;
          // that.filterDataShow = response.data.data;
          that.totalrows = parseInt(response.data.count);
          that.allpage = parseInt(response.data.allpage);
          that.searchResource();
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    deleteadmin(id) {
      var that = this;
      that
        .$confirm(
          that.$t("global.delete.deleteusertip"),
          that.$t("global.delete.sureyouaction"),
          {
            confirmButtonText: that.$t("global.button.ok"),
            cancelButtonText: that.$t("global.button.cancel"),
            type: "warning",
          }
        )
        .then(() => {
          that.loading = true;
          that.$axios
            .post(
              that.apiuri,
              {
                action: "delete",
                type: "user",
                id: id,
              },
              {
                headers: { Openid: that.userinfo.openid },
              }
            )
            .then(function (response) {
              that.loading = false;
              if (response.data.status == 200) {
                that.$message({
                  message: that.$t("global.delete.success"),
                  type: "success",
                });
              } else if (response.data.status == 403) {
                that.$message({
                  message: that.$t("global.delete.auth"),
                  type: "warning",
                });
              } else if (response.data.status == 404) {
                that.$message({
                  message: that.$t("device.orders.noorder"),
                  type: "warning",
                });
              } else {
                that.$message.error(that.$t("global.delete.500"));
              }
              that.getadminlist();
            })
            .catch(function (error) {
              console.log(error);
            });
        })
        .catch(() => { });
    },
    gettemporder() {
      var that = this;
    },

    searchResource() {
      this.currentPage = 1; //将当前页设置为1，确保每次都是从第一页开始搜
      let filterKeywords = this.adminsearch.trim(); //去掉两边空格
      if (filterKeywords.length === 0) {
        //搜索框为空直接将过滤后的数据赋给filterDataShow
        this.filterDataShow = this.adminlist;
        return;
      }
      let filerReasource = this.adminlist.filter(item => { //过滤全部数据
        if (item.username.includes(filterKeywords) || item.cardno.includes(filterKeywords) || item.phone.includes(filterKeywords)) {
          return item
        }
      })
      this.filterDataShow = filerReasource; //将符合条件的内容赋给filterDataShow
    },
    onSubmit() { },
    handleSizeChange(psize) {
      this.pagesize = psize;
    },
    handleCurrentChange(currentPage) {
      this.currentPage = currentPage;
    },
    exportToExcel() {
      let newdata = this.filterDataShow;
      for (let i = 0; i < newdata.length; i++) {
        if (newdata[i].status == 1) {
          newdata[i].status = this.$t("admin.lists.normal");
        } else if (newdata[i].status == 0) {
          newdata[i].status = this.$t("admin.lists.disable");
        }
      }
      const worksheet = XLSX.utils.json_to_sheet(newdata);
      XLSX.utils.sheet_add_aoa(worksheet, [
        [
          this.$t("admin.lists.userid"),
          this.$t("admin.lists.username"),
          this.$t("admin.lists.phone"),
          this.$t("user.list.membercard"),
          this.$t("user.list.membercardhex"),
          this.$t("user.list.amount"),
          this.$t('admin.lists.status')
        ]
      ],
        {
          origin: "A1"
        });
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
      const date = new Date();
      const year = date.getFullYear();
      const month = date.getMonth() + 1;
      const day = date.getDate();
      XLSX.writeFile(workbook, this.$t("user.list.name") + `${year}-${month}-${day}` + "tableData.xlsx");
    },
    updateuserinfo(id) {
      var that = this;
      that.$axios
        .post(
          that.apiuri,
          {
            action: "updateuserinfo",
            id: id,
          },
          {
            headers: { Openid: that.userinfo.openid },
          }
        )
        .then(function (response) {
          that.loading = false;
          if (response.data.status == 200) {
            that.$message({
              message: that.$t("global.alert.updateuser"),
              type: "success",
            });
          } else if (response.data.status == 403) {
            that.$message({
              message: that.$t("global.delete.auth"),
              type: "warning",
            });
          } else {
            if (response.data.msg) {
              that.$message.error(response.data.msg);
            } else {
              that.$message.error(that.$t("global.delete.500"));
            }
          }
          that.getadminlist();
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    getcashierconfig() {
      var that = this;
      that.loading = true;
      that.$axios
        .post(
          that.apiuri,
          {
            action: "get_cashier_config",
          },
          {
            headers: { Openid: that.userinfo.openid },
          }
        )
        .then(function (response) {
          that.loading = false;
          if (response.data.status == 200) {
            that.cashierconfig = response.data.config
            that.cashierstatus = that.cashierconfig.status
            if (that.cashierconfig.type == "hualala") {
              that.cashiersetting = false
            }
          }

        })
        .catch(function (error) {
          console.log(error);
        });
    },
    withdrawal(uid) {
      var that = this;
      that.$axios
        .post(
          that.apiuri,
          {
            action: "getwithdrawalvalue",
            uid: uid,
          },
          {
            headers: { Openid: that.userinfo.openid },
          }
        )
        .then(function (response) {
          if (response.data.amount == 0) {
            that.$message.error(that.$t("user.detail.amountlog.withdrawalfail"))
          } else {
            that.$prompt(`请输入提现金额，当前可提现${response.data.amount}`, '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              inputPattern: /(^[1-9]([0-9]+)?(\.[0-9]{1,2})?$)|(^(0){1}$)|(^[0-9]\.[0-9]([0-9])?$)/,
              inputErrorMessage: '请输⼊正确的格式,可保留两位⼩数'
            }).then(({ value }) => {
              that.loading = true;
              that.$axios
                .post(
                  that.apiuri,
                  {
                    action: "dowithdrawal",
                    uid: uid,
                    amount: value,
                  },
                  {
                    headers: { Openid: that.userinfo.openid },
                  }
                )
                .then(function (response) {
                  that.loading = false;
                  if (response.data.status == 200) {
                    that.$message({
                      message: that.$t("user.detail.amountlog.withdrawalsuccess"),
                      type: "success",
                    });
                    that.getadminlist();
                  } else {
                    that.$message.error(that.$t("user.detail.amountlog.withdrawalfail"))
                  }

                })
                .catch(function (error) {
                  console.log(error);
                });
            }).catch(() => {
              
            });
          }

        })
        .catch(function (error) {
          console.log(error);
        });
    },
    getwithdrawalstatus() {
      var that = this;
      that.$axios
        .post(
          that.apiuri,
          {
            action: "withdrawalstatus",
          },
          {
            headers: { Openid: that.userinfo.openid },
          }
        )
        .then(function (response) {
          if (response.data.status == 200) {
            that.withdrawalstatus = true
          } else {
            that.withdrawalstatus = false
          }

        })
        .catch(function (error) {
          console.log(error);
        });
    },
    getchargetype() {
      var that = this;
      that.$axios
        .post(
          that.apiuri,
          {
            action: "chargetype",
          },
          {
            headers: { Openid: that.userinfo.openid },
          }
        )
        .then(function (response) {
          if (response.data.status == 200) that.chargetype = 1;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    makechargeamount() {
      if (this.chargedata.uid == "") {
        this.$message.error(this.$t("user.detail.amountlog.selectuser"))
        return false
      }
      if (this.chargedata.amount == "" || this.chargedata.amount <= 0) {
        this.$message.error(this.$t("user.detail.amountlog.inputamount"))
        return false
      }
      var that = this;
      that.$axios
        .post(
          that.apiuri,
          {
            action: "makechargeamount",
            uid: that.chargedata.uid,
            amount: that.chargedata.amount,
          },
          {
            headers: { Openid: that.userinfo.openid },
          }
        )
        .then(function (response) {
          if (response.data.status == 200) {
            that.$message({
              message: that.$t("user.detail.amountlog.chargesuccess"),
              type: "success",
            });
            that.showamountchange = false
          } else {
            that.$message.error(that.$t("user.detail.amountlog.chargefail"))
          }

        })
        .catch(function (error) {
          console.log(error);
        });
    }
  },
  watch: { //监听搜索框内容，当搜索框内容发生变化时调用searchResource方法
    adminsearch: {
      handler() {
        this.searchResource()
      }

    }

  },
  computed: {
    showTable() {
      return this.filterDataShow.slice(
        (this.currentPage - 1) * this.pagesize,
        this.currentPage * this.pagesize
      );
    }
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body" v-loading="loading">
            <div class="row col-md-12">
              <p>
                <router-link :to="{ path: '/user/add' }">
                  <el-button type="success" round>{{
                    $t("admin.add.text")
                  }}</el-button>
                </router-link>
                <router-link :to="{ path: '/user/group' }" v-if="systemtype == 2">
                  <el-button type="primary" round>{{
                    $t("admin.group.text")
                  }}</el-button>
                </router-link>
                <router-link :to="{ path: '/user/temporary' }" v-if="systemtype == 2">
                  <el-button type="info" round>{{
                    $t("admin.temporary.text")
                  }}</el-button>
                </router-link>
                <el-button type="primary" @click="exportToExcel" round>{{
                  $t("user.detail.amountlog.exportexcel")
                }}</el-button>
              </p>
              <p>
                <el-button type="success" round v-if="chargetype" @click="showamountchange = true">{{
                  $t("user.list.amountcharge")
                }}</el-button>
              </p>
            </div>
            <div class="row col-md-12">
              <input type="search" v-model="adminsearch" class="form-control" :placeholder="$t('user.list.search')" />
            </div>
            <div class="row col-md-12">
              <el-table :data="showTable" style="width: 100%">
                <el-table-column prop="username" :label="$t('admin.lists.username')">
                </el-table-column>
                <el-table-column prop="phone" :label="$t('admin.lists.phone')">
                </el-table-column>
                <el-table-column prop="cardno" :label="$t('user.list.membercard')">
                </el-table-column>
                <el-table-column :label="$t('user.list.membercardhex')">
                  <template slot-scope="scope">
                    <span v-if="scope.row.hex">
                      {{ scope.row.hex }}
                    </span>
                    <span v-else>
                      {{ $t("user.detail.cardnobind") }}
                    </span>
                  </template>
                </el-table-column>
                <el-table-column prop="amount" :label="$t('user.list.amount')">
                </el-table-column>
                <el-table-column :label="$t('admin.lists.status')">
                  <template slot-scope="scope">
                    <span v-if="scope.row.status == 1" class="badge badge-success">
                      {{ $t("admin.lists.normal") }}
                    </span>
                    <span v-else-if="scope.row.status == 0" class="badge badge-danger">
                      {{ $t("admin.lists.disable") }}
                    </span>
                  </template>
                </el-table-column>
                <el-table-column :label="$t('device.lists.action')">
                  <template slot-scope="scope">
                    <router-link :to="{ path: '/user/detail', query: { id: scope.row.id } }">
                      <el-button size="mini" type="primary">{{
                        $t("user.list.info")
                      }}</el-button>
                    </router-link>
                    <router-link :to="{ path: '/user/add', query: { id: scope.row.id } }">
                      <el-button size="mini" type="primary">{{
                        $t("admin.lists.edit")
                      }}</el-button>
                    </router-link>
                    <el-button size="mini" type="success" @click="updateuserinfo(scope.row.id)"
                      v-if="cashierstatus && cashierconfig.type == 'youhao'">{{
                        $t("admin.lists.update")
                      }}</el-button>
                    <br v-if="withdrawalstatus" />
                    <el-button size="mini" type="warning" @click="withdrawal(scope.row.id)" v-if="withdrawalstatus">{{
                      $t("admin.lists.withdrawal")
                    }}</el-button>
                    <br />
                    <el-button size="mini" type="danger" @click="deleteadmin(scope.row.id)">{{ $t("device.lists.delete")
                    }}</el-button>
                  </template>
                </el-table-column>
              </el-table>
            </div>
            <div class="resource-page">
              <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                :page-sizes="[10, 20, 25]" :page-size="pagesize" layout=" prev, pager, next,sizes, jumper"
                :total="filterDataShow.length">
              </el-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Dialog -->
    <el-dialog :title="$t('user.detail.changeamount')" :visible.sync="showamountchange" :close-on-click-modal="false"
      :close-on-press-escape="false">
      <el-form :model="chargedata">
        <el-form-item :label="$t('admin.lists.username')">
          <el-select v-model="chargedata.uid" filterable :placeholder="$t('global.text.select')">
            <el-option v-for="item in filterDataShow" :key="item.id" :label="item.username" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('user.detail.price')">
          <el-input v-model="chargedata.amount" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="showamountchange = false">{{
          $t("global.button.cancel")
        }}</el-button>
        <el-button type="primary" @click="makechargeamount()">{{
          $t("global.button.ok")
        }}</el-button>
      </div>
    </el-dialog>
  </Layout>
</template>
<style scoped>
.resource-page {
  margin-top: 20px;
  text-align: center;
}
</style>